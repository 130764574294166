import { action, makeObservable, observable } from "mobx";

interface UiState {
  isLoggingIn: boolean;
}

type registerDone = (userId: string) => void;

class RegisterViewModel {
  uiState: UiState = { isLoggingIn: false };

  private registerDone: registerDone;
  constructor(onRegisterDone: registerDone) {
    this.registerDone = onRegisterDone;
    makeObservable(this, { uiState: observable, setUiState: action });
  }

  setUiState = (uiState: UiState) => {
    this.uiState = uiState;
  };

  login = (email: string, password: string) => {
    this.setUiState({ ...this.uiState, isLoggingIn: true });

    // TODO add api call
    this.registerDone("");
  };
}

export default RegisterViewModel;
