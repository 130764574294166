import React, { useMemo, useState } from "react";
import EditableTable from "./FlightLicensesForm";

import { Button, Popconfirm } from "antd";
import { FlightLicense } from "../UserData";
import { useForm } from "antd/es/form/Form";
import { v4 as uuidv4 } from "uuid";
import FlightLicenseViewModel from "./FlightLicenseViewModel";
import { observer } from "mobx-react";

type Item = FlightLicense;

const FlightLicenseInput = observer((props: { onContinue: () => void }) => {
  const flightLicenseViewModel = useMemo(() => {
    return new FlightLicenseViewModel();
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
        <Button
          onClick={() => {
            flightLicenseViewModel.addLicense();
          }}
        >
          Add
        </Button>
      </div>
      <div style={{ height: "10px" }} />
      <EditableTable dataSource={flightLicenseViewModel} />

      <div style={{ height: "10px" }} />
      <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
        <Button
          type="primary"
          htmlType="submit"
          style={{ width: "min-content" }}
          onClick={props.onContinue}
        >
          Continue
        </Button>
      </div>
    </div>
  );
});

export default FlightLicenseInput;
