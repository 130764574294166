import Navigator from "./navigationSwitch";

enum RootNavigationTargets {
  Empty,
  Register,
  LoggedIn,
}

const RootNavigator = new Navigator<RootNavigationTargets>();

const useRootNavigator = () => {
  return RootNavigator.navigator;
};

export default RootNavigationTargets;

export { RootNavigator, useRootNavigator };
