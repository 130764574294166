import React, { useMemo, useState, useContext } from "react";
import { Form, Input, Button, Typography } from "antd";
import RegisterViewModel from "./data/registerViewModel";
import { observer } from "mobx-react";
import RootNavigationTargets, {
  useRootNavigator,
} from "../../navigation/rootNavigator";
import { theUser } from "../../appState/userStore";
import DefaultFormInput from "../../partials/formInputContent";
import { useIsNarrowScreen } from "../../utils/windowSize";

const { Title } = Typography;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const useViewModel = () => {
  const navigationStore = useRootNavigator();

  return useMemo(() => {
    const onRegisterDone = (userId: string) => {
      theUser.id = userId;
      navigationStore.navigateTo(RootNavigationTargets.LoggedIn);
    };
    return new RegisterViewModel(onRegisterDone);
  }, []);
};

const RegisterPage = observer(() => {
  const [form] = Form.useForm();
  const navigator = useRootNavigator();
  const isNarrowScreen = useIsNarrowScreen();
  const formStyle: React.CSSProperties = {};
  if (!isNarrowScreen) {
    formStyle.maxWidth = "600px";
  }

  const viewModel = useViewModel();

  const onFinish = (values: { email: string; password: string }) => {
    viewModel.login(values.email, values.password);

    console.log("Received values of form: ", values);
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Title level={2} style={{ textAlign: "center" }}>
        Register
      </Title>
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        form={form}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        style={{ padding: "50px", maxWidth: "600px", width: "100%" }}
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              type: "email",
              message: "The input is not a valid email address!",
            },
            {
              required: true,
              message: "Please input your email!",
            },
          ]}
        >
          <div style={{ width: "100%", display: "flex" }}>
            <Input style={formStyle} />
          </div>
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <div style={{ width: "100%", display: "flex" }}>
            <Input.Password style={formStyle} />
          </div>
        </Form.Item>

        <Form.Item
          label="Confirm Password"
          name="confirm"
          dependencies={["password"]}
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The two passwords that you entered do not match!")
                );
              },
            }),
          ]}
        >
          <div style={{ width: "100%", display: "flex" }}>
            <Input.Password style={formStyle} />
          </div>
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={viewModel.uiState.isLoggingIn}
            style={{ display: "block", margin: "0 auto" }}
          >
            Login
          </Button>
        </Form.Item>
      </Form>

      <div>
        <p>Already have an account? </p>
        <Button
          type="link"
          onClick={() => {
            navigator.navigateTo(RootNavigationTargets.Empty);
          }}
        >
          Register
        </Button>
      </div>
    </div>
  );
});

export default RegisterPage;
