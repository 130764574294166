import { makeObservable, observable } from "mobx";

class TheUser {
  id: string = "";
  isLoggedIn: boolean = false;
  isTeacher: boolean = false;

  constructor() {
    makeObservable(this, {
      id: observable,
      isLoggedIn: observable,
    });
  }
}

const theUser = new TheUser();

export default TheUser;
export { theUser };
