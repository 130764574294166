import { observer } from "mobx-react";
import React, { useContext, useEffect } from "react";
import NavigationStore from "../appState/navigationStore";

class Navigator<T> {
  name: string;
  navigator: NavigationStore;
  constructor(name?: string) {
    this.name = name || "";
    this.navigator = new NavigationStore(this.name);
  }

  Switch = observer((props: { targets: Map<T, () => JSX.Element> }) => {
    const currentLocation = this.navigator.currentLocation;

    const Target = props.targets.get(this.navigator.currentLocation as T);
    if (Target !== undefined) {
      console.log(
        "Target found",
        this.navigator.name,
        this.navigator.currentLocation
      );
      return Target();
    } else {
      console.log("Target not found");
      return <></>;
    }
  });
}

export default Navigator;
