import { Table, Modal, Form, Input, Select } from "antd";
import { useEffect, useState } from "react";
import { FlightLicense } from "../UserData";

type Dataset = FlightLicense;

type EditableCellProps = {
  title: string;
  record: Dataset;
  isEditing: boolean;
  handleSave: (record: Dataset) => void;
  handleCancel: () => void;
};

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
  title,
  record,
  handleSave,
  handleCancel,
  isEditing,
}) => {
  const saveForm = async () => {
    try {
      const values = await form.validateFields();
      const newData = { ...record, ...values };
      handleSave(newData);
    } catch (err) {
      console.log("Error", err);
      handleCancel();
    }
  };

  const [form] = Form.useForm();

  useEffect(() => {
    if (record != undefined) {
      setTimeout(() => {
        form.resetFields();
      }, 1);
      form.resetFields();
    }
  }, [record]);

  return (
    <Modal
      open={isEditing}
      onOk={saveForm}
      onCancel={handleCancel}
      title={title}
    >
      <Form form={form} layout="vertical" initialValues={record}>
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              message: "Please input a name",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="type"
          label="Type"
          rules={[
            {
              required: true,
              message: "Please select a type",
            },
          ]}
        >
          <Select>
            <Select.Option value="Type A">Type A</Select.Option>
            <Select.Option value="Type B">Type B</Select.Option>
            <Select.Option value="Type C">Type C</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="from"
          label="From"
          rules={[
            {
              required: true,
              message: "Please input a a party who gave the license",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item name="number" label="Number">
          <Input type="number" />
        </Form.Item>

        <Form.Item name="notes" label="Notes">
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export { EditableCell };
