import React, { useState } from "react";
import { Form, Input, Button, DatePicker, Typography, InputNumber } from "antd";
import { UserData } from "./UserData";
import useWindowDimensions, { useIsNarrowScreen } from "../../utils/windowSize";
import DefaultFormInput from "../../partials/formInputContent";
import { observer } from "mobx-react";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const UserDataInputForm = observer(
  (props: { onSetValue: (user: UserData) => void }) => {
    const [form] = Form.useForm();
    const isNarrowScreen = useIsNarrowScreen();
    const formStyle: React.CSSProperties = {};
    if (!isNarrowScreen) {
      formStyle.maxWidth = "600px";
    }

    return (
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        form={form}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={props.onSetValue}
        style={{ padding: "10px", maxWidth: "600px", width: "100%" }}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Please input your name!" }]}
        >
          <div style={{ width: "100%", display: "flex" }}>
            <Input style={formStyle} />
          </div>
        </Form.Item>

        <Form.Item
          label="Surname"
          name="surname"
          rules={[{ required: true, message: "Please input your surname!" }]}
        >
          <div style={{ width: "100%", display: "flex" }}>
            <Input style={formStyle} />
          </div>
        </Form.Item>

        <Form.Item
          label="Birthdate"
          name="birthdate"
          rules={[{ required: true, message: "Please select your birthdate!" }]}
        >
          <div style={{ width: "100%", display: "flex" }}>
            <DatePicker format={"DD.MM.YYYY"} />
          </div>
        </Form.Item>

        <Form.Item
          label="Phone Number"
          name="phone"
          rules={[
            { required: true, message: "Please input your phone number!" },
          ]}
        >
          <div style={{ width: "100%", display: "flex" }}>
            <Input style={formStyle} />
          </div>
        </Form.Item>

        <Form.Item label="Website" name="website">
          <div style={{ width: "100%", display: "flex" }}>
            <Input style={formStyle} />
          </div>
        </Form.Item>

        <Form.Item
          label="Body Height"
          name="bodyHeight"
          rules={[
            { required: true, message: "Please input your body height!" },
          ]}
        >
          <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
            <InputNumber style={formStyle} /> <div style={{ width: "10px" }} />
            <div style={{ fontSize: "14px" }}>cm</div>
          </div>
        </Form.Item>

        <Form.Item
          label="Body Weight"
          name="bodyWeight"
          rules={[
            { required: true, message: "Please input your body weight!" },
          ]}
        >
          <div style={{ width: "100%", display: "flex" }}>
            <InputNumber style={{ ...formStyle }} />
            <div style={{ width: "10px" }} />
            <div style={{ fontSize: "14px" }}>kg</div>
          </div>
        </Form.Item>

        <Form.Item {...tailLayout}>
          <div style={{ width: "100%", display: "flex" }}>
            <Button type="primary" htmlType="submit">
              Continue
            </Button>
          </div>
        </Form.Item>
      </Form>
    );
  }
);

export default UserDataInputForm;
