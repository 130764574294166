import { Typography } from "antd";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import AddressInputForm from "../userData/AddressInput";
import FlightLicensesForm from "../userData/FlightLicenses";
import UserDataInputForm from "../userData/UserInputForm";

const { Title } = Typography;

const RegisterUserData = (props: { endOfRegisterFlow: () => void }) => {
  const [registerStep, setRegisterStep] = useState(0);

  const goToNextStep = () => {
    if (registerStep > 2) {
      props.endOfRegisterFlow();
    }

    setRegisterStep(registerStep + 1);
  };

  let content = <></>;
  switch (registerStep) {
    case 0:
      content = <BasicData onNext={goToNextStep} />;
      break;

    case 1:
      content = <AddressInput onNext={goToNextStep} />;
      break;

    case 2:
      content = <FlightLicenses onNext={goToNextStep} />;
      break;
  }

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Title level={2} style={{ textAlign: "center" }}>
        About you...
      </Title>
      {content}
    </div>
  );
};

const BasicData = observer((props: { onNext: () => void }) => {
  return (
    <RegisterBlock header="Basic Data">
      <UserDataInputForm
        onSetValue={() => {
          props.onNext();
        }}
      />
    </RegisterBlock>
  );
});

const AddressInput = observer((props: { onNext: () => void }) => {
  return (
    <RegisterBlock header="Address">
      <AddressInputForm
        onSetValue={() => {
          props.onNext();
        }}
      />
    </RegisterBlock>
  );
});

const FlightLicenses = observer((props: { onNext: () => void }) => {
  return (
    <RegisterBlock header="Flight Licenses">
      <FlightLicensesForm onContinue={props.onNext} />
    </RegisterBlock>
  );
});

const RegisterBlock = observer(
  (props: React.PropsWithChildren<{ header: string }>) => {
    return (
      <>
        <Title level={3} style={{ textAlign: "start", marginTop: "5px" }}>
          {props.header}
        </Title>
        {props.children}
      </>
    );
  }
);

export default RegisterUserData;
