import React, { useEffect, useState } from "react";
import { Table, Input, InputNumber, Popconfirm, Form } from "antd";
import { EditableCell } from "./EditableCell";
import { emptyFlightLicense, FlightLicense } from "../UserData";
import FlightLicenseViewModel from "./FlightLicenseViewModel";
import { observer } from "mobx-react";

interface FlightLicensesFormProps {
  dataSource: FlightLicenseViewModel;
}

const FlightLicensesForm: React.FC<FlightLicensesFormProps> = observer(
  ({ dataSource }) => {
    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        editable: false,
        width: 500,
      },
      {
        title: "Type",
        dataIndex: "type",
        editable: false,
        width: 300,
      },
      {
        title: "Action",
        dataIndex: "operation",
        render: (_: any, record: FlightLicense) => {
          return (
            <div>
              <a
                onClick={() => {
                  dataSource.editLicense(record);
                }}
              >
                Edit
              </a>
              <div style={{ width: "20px" }} />
              <a
                onClick={() => {
                  dataSource.deleteLicense(record);
                }}
              >
                Delete
              </a>
            </div>
          );
        },
      },
    ];

    return (
      <>
        <Table
          bordered
          dataSource={dataSource.licenses}
          columns={columns}
          rowClassName="editable-row"

          // pagination={{
          //   onChange: () => {},
          // }}
        />

        <EditableCell
          handleCancel={() => {
            dataSource.cancleEdit();
          }}
          handleSave={(license) => {
            dataSource.saveEdit(license);
          }}
          isEditing={dataSource.currentRecordInEdit !== undefined}
          record={dataSource.currentRecordInEdit || { ...emptyFlightLicense }}
          title={"Flight License"}
        />
      </>
    );
  }
);

export default FlightLicensesForm;
