import { makeObservable, observable, action } from "mobx";
import React from "react";

class NavigationStore {
  currentLocation: number = 0;
  name = "";
  navigateTo = (location: number) => {
    this.currentLocation = location;
  };

  constructor(name?: string) {
    this.name = name || "";
    makeObservable(this, {
      currentLocation: observable,
      navigateTo: action,
    });
  }
}
export default NavigationStore;
