import { useState } from "react";
import { Layout, Menu } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  DashboardOutlined,
  UserOutlined,
  CrownOutlined,
  HomeOutlined,
  ScheduleOutlined,
  RocketOutlined,
} from "@ant-design/icons";

import "./sideBarNavigation.scss";
import { NavigationItem, NavigatorProps } from "./navigatorProps";
import { observer } from "mobx-react";

const { Sider } = Layout;

const NavigationWideScreen = observer(
  (props: React.PropsWithChildren<NavigatorProps>) => {
    const [collapsed, setCollapsed] = useState(false);

    const toggleCollapse = () => {
      setCollapsed(!collapsed);
    };

    /*
  <HomeOutlined />
  <ScheduleOutlined />
  <RocketOutlined />

  <DashboardOutlined />
  <UserOutlined />
  <CrownOutlined />
   */

    const menuItems = props.navigationTargets.map((item) => {
      return (
        <Menu.Item key={`${item.id}`} icon={item.icon}>
          {item.name}
        </Menu.Item>
      );
    });

    return (
      <>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          className={"navigation-wide-screen-wrapper"}
          style={{ height: "100%" }}
        >
          <div onClick={toggleCollapse} className="collapse-button">
            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </div>
          <div className="logo" />
          <Menu
            theme={"light"}
            mode="inline"
            selectedKeys={[props.selectedItem]}
            onClick={(event) => {
              props.onItemSelected(event.key);
            }}
          >
            {menuItems}
          </Menu>
        </Sider>
        <div className={"content-wrapper"}></div>
      </>
    );
  }
);

export default NavigationWideScreen;
