import "./App.css";
import LoginPage from "./features/authenticaion/login";
import RegisterPage from "./features/authenticaion/register";
import "./styles/studentPage.scss";
import RootNavigationTargets, {
  RootNavigator,
} from "./navigation/rootNavigator";
import LoggedIn from "./screens/loggedIn";

function App() {
  return (
    <div className="App">
      <RootNavigator.Switch
        targets={
          new Map([
            [
              RootNavigationTargets.Empty,
              () => {
                return <LoginPage />;
              },
            ],
            [
              RootNavigationTargets.LoggedIn,
              () => {
                return <LoggedIn />;
              },
            ],
            [
              RootNavigationTargets.Register,
              () => {
                return <RegisterPage />;
              },
            ],
          ])
        }
      />
    </div>
  );
}

export default App;
