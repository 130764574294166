import { FlightLicense } from "../UserData";
import { v4 as uuidv4 } from "uuid";
import { action, makeObservable, observable } from "mobx";

class FlightLicenseViewModel {
  licenses: FlightLicense[] = [];

  currentRecordInEdit: FlightLicense | undefined = undefined;

  constructor() {
    makeObservable(this, {
      updateDataSet: action,
      licenses: observable,
      currentRecordInEdit: observable,
      editLicense: observable,
      cancleEdit: action,
    });
  }

  updateDataSet = (license: FlightLicense) => {
    if (license.key === "") {
      license.key = uuidv4();
      this.licenses.push(license);
      this.updateLicenses(this.licenses);
    } else {
      const indexToUpdate = this.licenses.findIndex((licenseInList) => {
        return licenseInList.key === license.key;
      });

      if (indexToUpdate < 0) {
        return;
      }

      this.licenses.splice(indexToUpdate, 1, license);
      this.updateLicenses(this.licenses);
    }
  };

  updateLicenses = (newLicenses: FlightLicense[]) => {
    this.licenses = newLicenses.map((val) => val);
  };

  editLicense = (license: FlightLicense) => {
    this.currentRecordInEdit = license;
  };

  deleteLicense = (license: FlightLicense) => {
    const indexDelete = this.licenses.findIndex((licenseInList) => {
      return licenseInList.key === license.key;
    });

    if (indexDelete < 0) {
      return;
    }

    this.licenses.slice(indexDelete, 1);
    this.updateLicenses(this.licenses);
  };

  cancleEdit = () => {
    this.currentRecordInEdit = undefined;
  };

  saveEdit = (license: FlightLicense) => {
    this.updateDataSet(license);
    this.cancleEdit();
  };

  addLicense = () => {
    this.editLicense({
      key: "",
      type: "",
      name: "",
      from: "",
      number: "",
      notes: "",
    });
  };
}

export default FlightLicenseViewModel;
