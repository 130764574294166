import { observer } from "mobx-react";
import { useEffect } from "react";
import { theUser } from "../appState/userStore";
import MainScreenNavigation from "../features/mainscreen";
import RegisterUserData from "../features/onBoarding/UserInputForm";
import Navigator from "../navigation/navigationSwitch";

enum LoggedInNavTargets {
  Start,
  OnBoarding,
  MainScreen,
}
const LoggedInNavigator = new Navigator<LoggedInNavTargets>("TEst");

const useLoggedInNavigator = () => {
  return LoggedInNavigator.navigator;
};

const LoggedIn = observer(() => {
  return (
    <LoggedInNavigator.Switch
      targets={
        new Map([
          [
            LoggedInNavTargets.Start,
            () => {
              return <LoggedInStartScreen />;
            },
          ],
          [
            LoggedInNavTargets.OnBoarding,
            () => {
              const navigation = useLoggedInNavigator();
              return (
                <RegisterUserData
                  endOfRegisterFlow={() => {
                    navigation.navigateTo(LoggedInNavTargets.MainScreen);
                  }}
                />
              );
            },
          ],
          [
            LoggedInNavTargets.MainScreen,
            () => {
              return <MainScreenNavigation />;
            },
          ],
        ])
      }
    />
  );
});

const LoggedInStartScreen = observer(() => {
  const navigation = useLoggedInNavigator();

  useEffect(() => {
    navigation.navigateTo(LoggedInNavTargets.MainScreen);
  }, []);

  return <></>;
});

export default LoggedIn;
export { LoggedIn, LoggedInNavTargets };
