import React, { useState } from "react";
import { Form, Input, Button, DatePicker, Typography, InputNumber } from "antd";
import { UserAddress } from "./UserData";
import useWindowDimensions, { useIsNarrowScreen } from "../../utils/windowSize";
import DefaultFormInput from "../../partials/formInputContent";
import { observer } from "mobx-react-lite";
import { Select } from "antd";

const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const AddressInputForm = observer(
  (props: { onSetValue: (user: UserAddress) => void }) => {
    const [form] = Form.useForm();
    const isNarrowScreen = useIsNarrowScreen();
    const formStyle: React.CSSProperties = {};
    if (!isNarrowScreen) {
      formStyle.maxWidth = "600px";
    }

    return (
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        form={form}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={props.onSetValue}
        style={{ padding: "50px", maxWidth: "600px", width: "100%" }}
      >
        <Form.Item
          label="Street and Number"
          name="street"
          rules={[{ required: true, message: "Please input your Street!" }]}
        >
          <div style={{ width: "100%", display: "flex" }}>
            <Input style={formStyle} />
          </div>
        </Form.Item>

        <Form.Item
          label="PLZ"
          name="zipCode"
          rules={[{ required: true, message: "Please input your PLZ!" }]}
        >
          <div style={{ width: "100%", display: "flex" }}>
            <InputNumber style={formStyle} />
          </div>
        </Form.Item>

        <Form.Item
          label="City"
          name="city"
          rules={[{ required: true, message: "Please input your City!" }]}
        >
          <div style={{ width: "100%", display: "flex" }}>
            <Input style={formStyle} />
          </div>
        </Form.Item>

        <Form.Item label="Country" name="country">
          <div style={{ width: "100%", display: "flex" }}>
            <CountrySelect style={{ ...formStyle, width: "150px" }} />
          </div>
        </Form.Item>

        <Form.Item {...tailLayout}>
          <div style={{ width: "100%", display: "flex" }}>
            <Button type="primary" htmlType="submit">
              Continue
            </Button>
          </div>
        </Form.Item>
      </Form>
    );
  }
);

const CountrySelect = (props: {
  style?: React.CSSProperties;
  className?: string;
}) => {
  return (
    <div style={props.style} className={props.className}>
      <Select style={{ width: "200px" }}>
        {countries.map((country) => (
          <Option key={country} value={country}>
            {country}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default AddressInputForm;

const countries = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Côte d'Ivoire",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo (Congo-Brazzaville)",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czechia (Czech Republic)",
  "Democratic Republic of the Congo",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini (fmr. Swaziland)",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Holy See",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar (formerly Burma)",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "North Korea",
  "North Macedonia",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Korea",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor-Leste (East Timor)",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States of America",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];
