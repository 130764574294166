import { action, makeObservable, observable } from "mobx";

interface UiState {
  isLoggingIn: boolean;
}

type LoggedIn = (userId: string) => void;

class LoginViewModel {
  uiState: UiState = { isLoggingIn: false };

  private loggedIn: LoggedIn;
  constructor(onLoggedIn: LoggedIn) {
    this.loggedIn = onLoggedIn;
    makeObservable(this, { uiState: observable, setUiState: action });
  }

  setUiState = (uiState: UiState) => {
    this.uiState = uiState;
  };

  login = (email: string, password: string) => {
    this.setUiState({ ...this.uiState, isLoggingIn: true });

    // TODO add api call
    this.loggedIn("");
  };
}

export default LoginViewModel;
