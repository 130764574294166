import React, { useMemo, useState } from "react";
import { Form, Input, Button, Typography } from "antd";
import { observer } from "mobx-react";
import RootNavigationTargets, {
  RootNavigator,
} from "../../navigation/rootNavigator";
import LoginViewModel from "./data/loginViewModel";

import DefaultFormInput from "../../partials/formInputContent";
import { useIsNarrowScreen } from "../../utils/windowSize";

const { Title } = Typography;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 1, span: 16 },
};

const useViewModel = () => {
  const navigator = RootNavigator.navigator;
  return useMemo(() => {
    return new LoginViewModel(() => {
      navigator.navigateTo(RootNavigationTargets.LoggedIn);
    });
  }, []);
};

const LoginPage = observer(() => {
  const [form] = Form.useForm();
  const navigator = RootNavigator.navigator;
  const viewModel = useViewModel();
  const isNarrowScreen = useIsNarrowScreen();
  const formStyle: React.CSSProperties = {};
  if (!isNarrowScreen) {
    formStyle.maxWidth = "600px";
  }

  const onFinish = (values: any) => {
    viewModel.login(values.email, values.password);
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Title level={2} style={{ textAlign: "center" }}>
        Login
      </Title>
      <Form
        form={form}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        style={{ padding: "50px", maxWidth: "600px", width: "100%" }}
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              type: "email",
              message: "The input is not a valid email address!",
            },
            {
              required: true,
              message: "Please input your email!",
            },
          ]}
        >
          <div style={{ width: "100%", display: "flex" }}>
            <Input style={formStyle} />
          </div>
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <div style={{ width: "100%", display: "flex" }}>
            <Input.Password style={formStyle} />
          </div>
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 0, span: 0 }}>
          <Button
            type="primary"
            htmlType="submit"
            loading={viewModel.uiState.isLoggingIn}
          >
            Login
          </Button>
        </Form.Item>
      </Form>

      <div>
        <p>Don't have an account? </p>
        <Button
          type="link"
          onClick={() => {
            navigator.navigateTo(RootNavigationTargets.Register);
          }}
        >
          Register
        </Button>
      </div>
    </div>
  );
});

export default LoginPage;
