import React, { useState } from "react";
import { NavigationItem, NavigatorProps } from "./navigatorProps";
import { theme } from "antd";

const BottomNavigator = (props: React.PropsWithChildren<NavigatorProps>) => {
  const menuItems = props.navigationTargets.map((item) => {
    return (
      <MenuItem
        item={item}
        isSelectedItem={item.id === props.selectedItem}
        onSelectThis={() => {
          props.onItemSelected(item.id);
        }}
      />
    );
  });

  return (
    <div
      className="bottom-nav"
      style={{
        position: "absolute",
        bottom: "10px",
        left: "0",
        width: "100%",
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        height: "50px",
      }}
    >
      {menuItems}
    </div>
  );
};

const MenuItem = ({
  item,
  isSelectedItem,
  onSelectThis,
}: {
  item: NavigationItem;
  isSelectedItem: boolean;
  onSelectThis: () => void;
}) => {
  const [isHover, setIsHover] = useState(false);

  const token = theme.useToken().token;
  const mainColor = token.colorPrimary;

  let background = undefined;
  if (isSelectedItem) {
    background = token.colorPrimaryBgHover;
  } else if (isHover) {
    background = token.colorBgTextHover;
  }

  let fastTransition = true;
  if (isSelectedItem || !isHover) {
    fastTransition = false;
  }

  return (
    <div
      key={item.id}
      style={{
        display: "flex",
        flexGrow: "1",
        justifyContent: "center",
        alignItems: "center",
        color: isSelectedItem ? mainColor : undefined,
        height: "40px",
        marginLeft: "10px",
        marginRight: "10px",
        borderRadius: "10px",
        maxWidth: "200px",
        background: background,
        cursor: "pointer",
        transitionProperty: "all",
        transitionDuration: fastTransition ? "200ms" : "1000ms",
      }}
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      onClick={() => {
        onSelectThis();
      }}
    >
      {item.icon}
    </div>
  );
};

export default BottomNavigator;
