export interface UserData {
  uuid: string;
  name: string;
  surname: string;
  birthdate: string;
  phone: string;
  username: string;
  email: string;
  website: string;
  bodyHeight: number;
  bodyWeight: number;
}

export interface UserAddress {
  userUuid: string;
  street: string;
  zipCode: number;
  city: string;
  country: string;
}

export interface FlightLicense {
  key: string;
  type: string;
  name: string;
  from: string;
  number: string;
  notes: string;
}

export const emptyFlightLicense: FlightLicense = {
  key: "",
  type: "",
  name: "",
  from: "",
  number: "",
  notes: "",
};
