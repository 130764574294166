import { useEffect, useState } from "react";
import NavigationWideScreen from "./navigationWideScreen";
import { observer } from "mobx-react";
import { NavigationItem, NavigatorProps } from "./navigatorProps";
import {
  HomeOutlined,
  RocketOutlined,
  ScheduleOutlined,
} from "@ant-design/icons";
import BottomNavigator from "./BottomNavigator";

const Navigation = observer(() => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("1");

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const navItems: NavigationItem[] = [
    {
      icon: <HomeOutlined />,
      name: "Grundausbildung",
      id: "1",
    },
    {
      icon: <ScheduleOutlined />,
      name: "A-Lizenz",
      id: "2",
    },
    {
      icon: <RocketOutlined />,
      name: "Höhenflüge",
      id: "3",
    },
  ];

  const navProps: NavigatorProps = {
    navigationTargets: navItems,
    onItemSelected: (newItem) => {
      setSelectedItem(newItem);
    },
    selectedItem: selectedItem,
  };

  if (isSmallScreen) {
    return <BottomNavigator {...navProps} />;
  } else {
    return <NavigationWideScreen {...navProps} />;
  }
});

export default Navigation;
